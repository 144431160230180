import "./style.css";
import { t } from "i18next";
function App() {
  return (
    <>
      <div>
        <img src="../img/图层 28.png" alt="" />
      </div>
      <div className="text">
        <div>
          {t("XAI is an innovative smart app dedicated to the primary DeFi market and is the first project in the cryptocurrency circle to empower with eight major applications. As a modern and advanced digital financial  service, XAI provides a secure cryptocurrency wallet and investment solutions that will completely change the way we invest in  cryptocurrencies. With a solid foundation in trust, security, and  innovation, our platform offers users a seamless and convenient experience in managing their digital assets.")}
        </div>
        <div>
          {t("The XAI app includes a suite of features such as real-time quotes,news updates, integrated wallets, interactive charts, community forums, clipper bots, automated trading strategy bots, and Coin Circle Genie (price fluctuation monitoring). By leveraging the most advanced technology and pioneering ideas, we are committed to providing users with a safe and stable investment solution.")}
        </div>
        <div>
         {t("The development of the XAI app began in June 2022 with the primary goal of creating a leading cryptocurrency investment platform. Our mission is to provide users with secure investment strategies and actively contribute to the future of the cryptocurrency ecosystem.")}
        </div>
      </div>
      <div className="robot">
        <img src="../img/图层 29.png" alt="" />

        <button>Click to chat</button>
      </div>
      <div>
        <img src="../img/图层 30.png" alt="" />
      </div>
      <div className="mining">
        <div className="center">
          <span>Mining</span>
          <div></div>
          <div>
            view
            <img src="../img/图层 26.png" alt="" />
          </div>
        </div>
      </div>
      <div className="img">
        <img src="../img/图层 31.png" alt="" />
        <img src="../img/图层 32.png" alt="" />
        <img src="../img/图层 33.png" alt="" />
        <img src="../img/图层 34.png" alt="" />
        <img src="../img/图层 35.png" alt="" />
        <img src="../img/图层 36.png" alt="" />
      </div>
      <footer>
        <img src="../img/图层 9.png" alt="" />
        <div className="right">
          <div style={{display:"flex"}}>
            <img src="../img/图层 19.png" alt="" />
            <img src="../img/图层 19(1).png" alt="" />
          </div>
          <div>
            <p>Telegram： https://t.me/XAIEnglish</p>
            <p>
              x：https://x.com/AI7872853070301?t=FbVMCb0gpkQAEqa5WP44fA&s=09
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default App;
