function App() {
  return <>
  <img src="/img/k1.png" alt="" />
  <img src="/img/k2.png" alt="" />
  <img src="/img/k3.png" alt="" />
  <img src="/img/k4.png" alt="" />
  </>;
}

export default App;
