import { t } from "i18next";
function App() {
  return (
    <>
      <div className="sniperRobot">
        <p>
          {t("More and more low-income families are using the proceeds from crypto investments to apply for mortgages")}
        </p>
        <p>
         {t("[More and more low-income households are using the proceeds from crypto investments to apply for mortgages] Golden Finance reported that according to a report by the  U.S. Treasury Research Economist, an increasing number of low-income  households are using the proceeds from crypto investments to apply for mortgages. Households are using the proceeds from crypto investments to apply for mortgages. Among low-income households, “cryptocurrency sales may have supported access to larger mortgages through larger down payments,” the report said. In a report released on November 26 by the Treasury Department’s Office of Financial Research, researchers Samuel Hughes, Francisco Ilabaca, Jacob Lockwood and Kevin Zhao wrote.")}
        </p>
        <p>
         {t("'The increase in borrowing was particularly pronounced among low-income households with high cryptocurrency exposure,'they added. The report noted that in areas with high cryptocurrency exposure, the proportion of mortgage loans taken out by low-income households increased by more than 250%, with the average mortgage The loan balance rose from approximately $172,000 in 2020 to approximately $443,000 in 2024.")}
        </p>
        <p>
         {t("Nasdaq-listed Bitcoin mining company BTC Digital announced the expansion of mining facilities and expansion into four states in the United States")}
        </p>
        <p>
          {t("[BTC Digital, a Nasdaq-listed Bitcoin mining company, announced to expand the scale of mining facilities and expand to four states in the United States] Golden Finance reported that BTC Digital, a Nasdaq-listed Bitcoin mining company, announced to expand the scale and number of mining facilities, aiming to Meet the computing power needs of the growing crypto market and will expand business opportunities in Arkansas, Tennessee, Georgia and Missouri in the United States. In addition, BTC DIgital also revealed that it is conducting due diligence on 'a location' for potential acquisitions that could potentially support its mining capacity to reach 40 MW by mid-2025.")}
        </p>
        <p>
          {t("A certain address bought 12.5 million CHILLGUY in 12 days, with a total profit of US$6.14 million.")}
        </p>
        <p>
          {t("[An address bought 12.5 million CHILLGUY in 12 days, with a total profit of US$6.14 million] Golden Finance reported that according to Lookonchain monitoring, a trader only spent 0.75 SOL (US$160) to buy 1,250 SOL on Pump.fun 12 days ago. 10,000 CHILLGUY and then sell it for 149 SOL (35,400 USD) for 28,000 CHILLGUY. The trader still holds 9.62 million CHILLGUY ($6.1 million) for a total profit of $6.14 million")}
        </p>
        <p>
          {t("The market value of the holdings of the Trump family’s DeFi project World Liberty Fi has exceeded US$50 million")}
        </p>
        <p>
          {t("[The market value of the holdings of the Trump family’s DeFi project World Liberty Fi has exceeded US$50 million] Golden Finance reported that according to Arkham monitoring data, the market value of the holdings of the Trump family’s DeFi project World Liberty Fi has exceeded US$50 million, currently touching US$56,507,264.54. USDC is the cryptocurrency with the largest holdings (currently about 33.32 million coins), followed by ETH (currently about 4740 coins). It is reported that the increase in the market value of World Liberty Fi's holdings may be related to Sun Yuchen's investment. Previously, it was reported that Sun Yuchen was suspected of using an encrypted wallet belonging to the HTX exchange to purchase approximately 2 billion WLFI tokens. These tokens were purchased using USDC on the Ethereum network. of.")}
        </p>
        <p>
         {t("Elon Musk offers 25% stake in xAI to investors backing his $44 billion acquisition of Twitter")}
        </p>
        <p>
         {t("[Elon Musk has offered 25% of xAI shares to investors who supported his $44 billion acquisition of Twitter] Golden Finance reported that Elon Musk has offered 25% of xAI to investors who supported his $44 billion acquisition of Twitter (now known as X) of shares. xAI was founded last year to compete with the likes of OpenAI and Anthropic. xAI is set to close a new $5 billion funding round as early as Wednesday, people familiar with the matter said, doubling its valuation to $50 billion in just six months. When the funding round closes this week, xAI will have raised a total of about $11 billion to fund the massive spending required to build AI models and one of the world's largest supercomputer clusters.")}
        </p>

        <p>
         {t("That means some of Musk's backers, who lost billions when he acquiredTwitter, stand to profit handsomely from their stake in xAI as thestartup's value soars. Investors who will benefit from Musk's two companies include Fidelity, Oracle co-founder Larry Ellison, Saudi Prince Alwaleed bin Talal, Twitter founder Jack Dorsey and Silicon Valley venture capital firms Sequoia Capital and Andreessen Horowitz (a16z)")}
        </p>
        <p>
          {t("Alpha Liquid Fund establishes strategic partnership with XNET Foundation and plans to invest US$2 million to purchase tokens")}
        </p>
        <p>
         {t("Alpha Liquid Fund has established a strategic partnership with the will establish a strategic partnership and plans to invest US$2 million to purchase tokens in its XNET ecosystem. XNET currently mainly provides broadband wireless services on the Solana chain and deploys wireless networks in a decentralized manner.")}
        </p>
      </div>
    </>
  );
}

export default App;
