import "./App.css";
import Routers from "./route";
import { useNavigate } from "react-router-dom";
import {
  UsbOutlined,
  CommentOutlined,
  FundProjectionScreenOutlined,
  ReadOutlined,
  TransactionOutlined,
} from "@ant-design/icons";
import { t } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function App() {
  // 导航项数据
  const navItems = [
    {
      icon: <TransactionOutlined />,
      label: t("Transaction"),
      path: "https://xaiswap.me/",
    },
    { icon: <UsbOutlined />, label: t("robot"), path: "/robot" },
    { icon: <ReadOutlined />, label: t("news"), path: "/news" },
    {
      icon: <FundProjectionScreenOutlined />,
      label: t("k line"),
      path: "/kLine",
    },
    { icon: <CommentOutlined />, label: t("PostBar"), path: "/tieBa" },
  ];

  const navigate = useNavigate();
  const [activeRoute, setActiveRoute] = useState("");
  const handleNavigation = (path) => {
    if (path === "https://xaiswap.me/") {
      window.location.href = path;
    } else {
      setActiveRoute(path);
      navigate(path);
    }
  };
  const [showDropdown, setShowDropdown] = useState(false);
  const [language, setLanguage] = useState("English");
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const { i18n } = useTranslation();
  const selectLanguage = (lang) => {
    setLanguage(lang === "en" ? "English" : "中文");
    console.log(lang, "lang");
    setShowDropdown(false);
    i18n.changeLanguage(lang);
  };
  return (
    <>
      <header>
        <img className="logo" src="../img/图层 9.png" alt="" />
        <div
          className="language-toggle"
          onClick={toggleDropdown}
          onMouseEnter={() => setShowDropdown(true)}
          onMouseLeave={() => setShowDropdown(false)}
        >
          <div className="image">
            <img src="/img/language.png" alt="" />
          </div>
          <span>{language}</span>
          {showDropdown && (
            <div
              className="dropdown"
              onMouseEnter={() => setShowDropdown(true)}
              onMouseLeave={() => setShowDropdown(false)}
            >
              <div onClick={() => selectLanguage("en")}>English</div>
              <div onClick={() => selectLanguage("zh")}>中文</div>
            </div>
          )}
        </div>
      </header>
      <ul className="fixed">
        {navItems.map((item, index) => (
          <li
            key={index}
            onClick={() => handleNavigation(item.path)}
            className={activeRoute === item.path ? "nav-item" : undefined}
          >
            {item.icon}
            {item.label}
          </li>
        ))}
      </ul>
      <div className="body">
        <Routers />
      </div>
    </>
  );
}

export default App;
