import { Routes, Route } from "react-router-dom";
import Home from "../views/Home";
import News from "../views/News";
import TieBa from "../views/TieBa";
import Robot from "../views/Robot";
import KLine from "../views/KLine";
import React from "react";
const Routers = () => (
  <Routes>
    <Route>
      <Route path="/" element={<Home />} />
      <Route path="/news" element={<News />} />
      <Route path="/tieBa" element={<TieBa />} />
      <Route path="/robot" element={<Robot />} />
      <Route path="/kLine" element={<KLine />} />
    </Route>
  </Routes>
);

export default Routers;
