import { useState } from "react";
import "./style.css";
import { t } from "i18next";
function App() {
  const tabs = [
    { key: "sniperRobot", label: t("sniper robot") },
    { key: "ClipRobot", label: t("Clip robot") },
  ];
  const [activeTab, setActiveTab] = useState(tabs[0].key);
  const handleTabClick = (tab) => {
    setActiveTab(tab.key);
  };
  return (
    <>
      <div className="tabList">
        {tabs.map((tab) => (
          <div
            key={tab.key}
            className={`tab ${activeTab === tab.key ? "active" : ""}`}
            onClick={() => handleTabClick(tab)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className="content">
        <div>
          {activeTab === "sniperRobot" && <img src="/img/图层 29.png" alt="" />}
          {activeTab === "ClipRobot" && <img src="/img/image.png" alt="" />}
        </div>
        {activeTab === "sniperRobot" && (
          <div className="sniperRobot">
            <p>
              {t(
                "Unveiling the Power of Interceptor Robots in the Cryptocurrency World"
              )}
            </p>
            <p>
              {t(
                "In the ever-evolving landscape of the cryptocurrency market,speed, precision, and efficiency define success. Enter the groundbreaking technology of interceptor robots—your new frontline  defense and strategic asset in navigating the turbulent tides of digital currencies."
              )}
            </p>
            <p>
              {t(
                "Swift Decision-Making: Real-Time Market Analysis Interceptor robots are designed to operate at the forefront of speed and accuracy. Integrated with advanced algorithms, these robots constantly analyze market data at lightning speeds—far beyond human capabilities. They detect subtle shifts and potential volatility spikes by crunching massive datasets, enabling them to make informed decisions in milliseconds."
              )}
            </p>
            <p>
              {t(
                "Cutting-Edge Technology: Predictive Mechanisms At the heart of every interceptor robot is a predictive engine powered by artificial intelligence and machine learning. This technology not only tracks real-time market trends but also anticipates future movements. By understanding patterns and historical data, interceptor robots can predict market turns and initiate strategic operations to capitalize on or mitigate those movements."
              )}
            </p>
            <p>
              {t(
                "Precision Execution: Automated Trading Actions When it comes to execution, interceptor robots are unmatched. Whether it’s buying low or selling high, these robots execute trades with precision that minimizes slippage and maximizes returns. Coupled with high-frequency trading capabilities, they can maneuver large  orders in and out of the market, ensuring optimal execution without significant market impact."
              )}
            </p>
            <p>
              {t(
                "Dynamic Adaptation: Learning From the Market The intelligence of nterceptor robots does not just lie in their initial programming. They are dynamic learners—constantly evolving with every trade. hrough continuous back-testing and adaptation strategies, these robots refine their algorithms based on new information and market feedback, enhancing their efficiency and effectiveness over time."
              )}
            </p>
            <p>
              {t(
                "Security and Reliability: Your Trusted Guardian Security in trading cannot be overstated, and interceptor robots bring reliability to the table with robust encryption methods and tringent security protocols. Traders can rest assured that their operations are guarded against potential security threats, making interceptor robots a trusted partner in the volatile world of cryptocurrency trading."
              )}
            </p>
            <p>
              {t(
                "Join the Revolution: Embrace the Future Embrace the cutting-edge technology of interceptor robots and transform your trading experience. With unparalleled speed, accuracy, and intelligence, interceptor robots equip you to navigate the complex  cryptocurrency market with confidence. Prepare to lead the charge with every trade and stay one step ahead in the fast-paced digital  currency arena."
              )}
            </p>
            <p>
              {t(
                "Harness the power of interceptor robots and watch as they redefine cryptocurrency trading, delivering performance that propels you to the forefront of the market. Welcome to the future of trading, where technology meets strategy in the dynamic world of digital currencies."
              )}
            </p>
          </div>
        )}

        {activeTab === "ClipRobot" && (
          <div className="sniperRobot">
            <p>
              {t(
                "Crypto Trading Bots: Harnessing Automation for Enhanced Trading Efficiency"
              )}
            </p>

            <p>
              {t(
                "In the dynamic world of cryptocurrency trading, speed and precision are paramount. That's where crypto trading bots come into play, providing traders with a powerful tool to enhance their trading strategies and improve overall efficiency. These bots are automated software programs that interact directly with financial exchanges, making it possible to execute trades at optimal times based on predefined criteria."
              )}
            </p>
            <p>{t("How Crypto Trading Bots Work")}</p>

            <p>
              {t(
                "Crypto trading bots are designed to automate the trading process by continuously monitoring the market and executing trades based on set algorithms. These bots use market data to make decisions, pulling information such as price movements, volume, orders, and time, all of which are crucial for making trading decisions."
              )}
            </p>

            <p>
              {t(
                "Market Analysis: The bots analyze market conditions using technical indicators and can scan multiple cryptocurrencies across various exchanges. This multispectrum analysis helps identify potential buying or selling opportunities."
              )}
            </p>

            <p>
              {t(
                "Risk Assessment: Before executing any trades, bots evaluate the potential risk associated with the transaction. They can adjust their strategies dynamically based on real-time market conditions to optimize returns."
              )}
            </p>

            <p>
              {t(
                "Order Execution: Once a potential trade meets the set criteria, the bot will automatically execute the order. This can include determining the trade size, placing buy or sell orders, and even deciding the exit strategy."
              )}
            </p>
            <p>
              {t(
                "Continuous Optimization: Most sophisticated trading bots are capable of learning from past trades. Through what is known as machine learning, these bots refine their trading strategies over time to maximize success rates."
              )}
            </p>

            <p>{t("Benefits of Using Crypto Trading Bots")}</p>
            <p>
              {t(
                "Efficiency: Bots can process data and execute trades much faster than manual trading. - Emotionless Trading: Bots operate based on analytical data, removing emotional decisions that can lead to adverse trading outcomes. 24/7 Trading: Unlike human traders, bots can operate continuously, taking advantage of opportunities even when the trader is asleep or unable to monitor the market. Diversification: Trading bots can handle multiple accounts and  asset types simultaneously, spreading risk and increasing potential returns."
              )}
            </p>
            <p>
              {t(
                "Challenges and Considerations While trading bots offer numerous advantages, they also come with challenges. It's important to ensure that the bot is operating on a secure and reliable platform to avoid any potential security breaches. Additionally, traders should be wary of market anomalies that could affect the performance of the bot, such as flash crashes or extreme volatility."
              )}
            </p>
            <p>
              {t(
                "In conclusion, crypto trading bots represent a significant advancement in the way traders can interact with the cryptocurrency market. By automating the trading process, these bots help traders optimize their strategies, reduce manual effort, and improve their chances of trading success in an ever-evolving market."
              )}
            </p>
          </div>
        )}
      </div>
    </>
  );
}

export default App;
